import { startIfHTMLRequires } from '../init/startIfHTMLRequires'

function showPasswordAdvice () {
  const modalTarget = $('#child_authentication_password_modal').find('.child-authentication-password-modal__password-advice')

  $(document.body).on('click', '[data-login-user-id]', function (e) {
    const tile = $(e.target).closest('.js-child-tile')
    const policy = tile.find('[data-password-policy]').data('password-policy')
    let initialAdvice = ''
    let spacingAdvice = ''

    modalTarget.empty()

    if ($.isEmptyObject(policy) === false) {
      if (policy.id !== 'custom') {
        initialAdvice = `<li>Remember: your password is ${policy.details.lengthSpellOut} ${policy.details.typePluralised}.`
        if (policy.id.includes('number')) {
          initialAdvice += ` ${(policy.details.numSegments > 1) ? 'Each' : 'The'} number is between 0 and 99. For example: 1, 12, or 52.`
        }
        if (policy.details.numSegments > 1) {
          spacingAdvice = `<li>Leave a space between the ${policy.details.typePluralised}.`
        }
      } else if (policy.data.hint) { // a custom hint may be set, but only use it for custom policies
        initialAdvice = `<li> ${policy.data.hint}`
      }

      modalTarget.html(`
                To log in to Tapestry:
                <ol>
                    ${initialAdvice}
                    <li>Type ${policy.details.determiner} ${policy.details.typePluralised} in this box.
                    ${spacingAdvice}
                    <li>Then press log in.
                </ol>
            `)
    }
  })
}

startIfHTMLRequires('child_login_password_policy', showPasswordAdvice)

export { showPasswordAdvice }
