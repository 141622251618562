

/**
 * This checks the html for a div with a data-modern-javascript-function-id attribute
 * of the given name. If there is, it checks the content of that div for json encoded
 * arguments. It then calls the callback with the arguments.
 *
 * This is used to start up javascript with options specified on an HTML page.
 *
 * We do this because our content security policy prevents the running of inline javascript.
 *
 * @param {*} name
 * @param {*} callback
 */
const startIfHTMLRequires = function (name, callback) {
  function init (_, element) {
    const args = JSON.parse($(element).text())

    if (typeof callback === 'function') {
      callback(args)
    } else if (typeof callback === 'object' && callback.hasOwnProperty('init')) {
      callback.init(args)
    } else {
      console.error('Unknown callback type ' + (typeof callback))
    }
  }

  function find () {
    $(`div[data-modern-javascript-function-id='${name}']`).each(init)
  }

  $(find)

  // We return the find function so this method can be tested in jsdom
  // which does not otherwise trigger the jquery ready method.
  return find
}

export { startIfHTMLRequires }
