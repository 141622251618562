import { startIfHTMLRequires } from '../init/startIfHTMLRequires'

class RemoveBruteForceAttempts {
  constructor (options) {
    this.options = options

    this.setupLockedAccountsTimers()
    this.setupPolling()

    $('body').on('click', '.js-remove-brute-force-attempt', e => this.onRemoveClicked(e))
  }

  setupLockedAccountsTimers () {
    $('[data-lock-duration]').each((index, element) => {
      const timer = new easytimer.Timer()

      timer.start({ countdown: true, startValues: { seconds: $(element).data('lock-duration') } })

      $(element).html(timer.getTimeValues().toString(['minutes', 'seconds']))

      timer.addEventListener('secondsUpdated', () => {
        $(element).html(timer.getTimeValues().toString(['minutes', 'seconds']))
      })

      timer.addEventListener('targetAchieved', () => {
        $(element).parent('[data-user-id]').remove()

        if (this.shouldHideLockedAccounts()) {
          this.hideLockedAccounts()
        }
      })
    })
  }

  onRemoveClicked (e) {
    e.preventDefault()

    const form = $(e.target).parent('form')

    $.ajax({
      type: 'POST',
      data: form.serialize(),
      url: form.attr('action'),
      success: response => {
        this.getLockedAccounts().then(() => Olj.Feedback.show('success', response.message))
      }
    })
  }

  setupPolling () {
    window.setInterval(() => this.getLockedAccounts(), 30000)
  }

  getLockedAccounts () {
    return new Promise((resolve) => {
      $('.js-locked-accounts').load(this.options.lockedUsersUrl, () => {
        this.setupLockedAccountsTimers()
        resolve()
      })
    })
  }

  shouldHideLockedAccounts () {
    return $('.js-locked-accounts-table').find('tr').length === 1
  }

  hideLockedAccounts () {
    $('.js-locked-accounts').hide()
  }
}

startIfHTMLRequires('remove_brute_force_attempts', (options) => new RemoveBruteForceAttempts(options))
