
const scrollToElement = function (target, callback) {
  let offset = 0
  $('.main-nav-container').each((index, element) => {
    offset += $(element).height()
  })

  $('html, body').animate(
    {
      scrollTop: target.offset().top - offset
    },
    {
      duration: 250,
      complete: callback
    }
  )
}

const syncScroll = elements => {
  // We only want to listen to events from one scrolling element at a time
  let updating = false

  elements.on('scroll', e => {
    if (updating) {
      // This event was caused by us setting the scroll below, so ignore it
      return
    }

    // Ignore events from other elements
    updating = true

    // Get scroll value and apply to other elements
    const scroll = e.target.scrollLeft
    elements.not(e.target).each((index, other) => {
      other.scrollLeft = scroll
    })

    // Finished
    updating = false
  })
}

export { scrollToElement, syncScroll }
