const dialog = {

  init: function () {
    bootbox.setDefaults({
      animate: false
    })

    $(document).on('click', '[data-toggle=alert]', function (e) {
      e.preventDefault()

      dialog.alert($(this).data('alert-text'))
    })
  },

  alert: function (message, callback) {
    bootbox.alert(message, callback)
  },

  confirm: function (message, callback) {
    bootbox.confirm(message, callback)
  },

  prompt: function (message, callback) {
    if (typeof callback === 'undefined') {
      bootbox.prompt(message)
    } else {
      bootbox.prompt(message, callback)
    }
  },

  confirmDelete: function (message, callback) {
    bootbox.dialog({
      message: message,
      title: 'Confirm Deletion',
      buttons: {
        cancelBtn: {
          label: 'Cancel',
          className: 'btn-default'
        },
        deleteBtn: {
          label: 'Delete',
          className: 'btn-danger',
          callback: callback
        }
      }
    })
  },

  confirmUnlink: function (message, callback) {
    bootbox.dialog({
      message: message,
      title: 'Confirm Unlink',
      buttons: {
        cancelBtn: {
          label: 'Cancel',
          className: 'btn-default'
        },
        deleteBtn: {
          label: 'Unlink',
          className: 'btn-danger',
          callback: callback
        }
      }
    })
  }

}

export default dialog
