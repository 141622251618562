const ObsList = {
  options: {},

  init: function (options) {
    this.options = options
  },

  bulkReflect: function (ids) {
    window.location.assign(this.options.add_reflection_url + '?reflect_on_obs=' + ids.join(','))
  }
}

export { ObsList }
