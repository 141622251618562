import { startIfHTMLRequires } from '../init/startIfHTMLRequires.js'
import { debounce } from '../util/debounce'

class ReferenceItemItemsBox {
  constructor (options) {
    // This is a required option
    this.endpoint = options.endpoint
    // These are for stubbing out in tests
    this.load = options.load ?? $.ajax
    this.filterTriggers = options.filterTriggers ?? ((triggers) => { return triggers.filter(':visible') }) // For stubbing out in tests
    this.filterBoxes = options.filterBoxes ?? ((boxes) => { return boxes.filter(':visible') }) // For stubbing out in tests
    const debouncer = options.debouncer ?? debounce

    // Toggeable features
    this.disableDefaultLoadingSpinner = options.disableDefaultLoadingSpinner

    // Now we are starting
    this.debouncedUpdateItemsBoxesByTriggers = debouncer(() => this.updateItemBoxesByTriggers(), 250)
    this.triggered = this.getListOfTriggeredTriggers()
    this.registerForClickEvents()

    // Maybe do an initial update
    if (options.initialUpdate) {
      this.updateItemBoxesByTriggers()
    }
  }

  registerForClickEvents () {
    const body = $(document.body)
    body.on('click', 'a.reference-items__item[data-url-type]', e => this.onItemLinkClicked(e))
    body.on('click', '[data-new-length]', e => this.onPaginationLinkClicked(e))
  }

  triggerChanged () {
    this.triggered = this.getListOfTriggeredTriggers()
    this.debouncedUpdateItemsBoxesByTriggers()
  }

  getListOfTriggeredTriggers () {
    const result = {}
    this.filterTriggers($('[data-trigger-id]')).each((i, visible) => {
      const triggered = $(visible)
      const scope = triggered.data('trigger-scope')
      const id = triggered.data('trigger-id')
      if (result[scope] === undefined) { result[scope] = {} }
      result[scope][id] = true
    })
    return result
  }

  // If a modal box, only that updates, otherwise we update all those that are visible
  updateItemBoxesByTriggers () {
    this.updateModalBox() || this.updateAllVisibleBoxes()
  }

  updateModalBox () {
    const modalBox = $('.modal.in').find('[data-item-label-scope]')
    if (modalBox.length === 0) { return false }
    this.updateBoxIfTriggersChanged(modalBox)
    return true
  }

  updateAllVisibleBoxes () {
    this.filterBoxes($('[data-item-label-scope]')).each((i, box) => {
      this.updateBoxIfTriggersChanged($(box))
    })
    return true
  }

  updateBoxIfTriggersChanged ($box) {
    if (this.triggersChangedSinceLastUpdate($box)) {
      this.updateItemsBox($box)
    }
  }

  triggersChangedSinceLastUpdate ($box) {
    const triggersOnLastUpdate = $box.data('previous-triggers')
    if (triggersOnLastUpdate === undefined) { return true }
    const triggersNow = this.getTriggersForBox($box)
    return triggersOnLastUpdate.toString() !== triggersNow.toString()
  }

  getTriggersForBox ($box) {
    let result = []
    const boxTriggerScopes = $box.data('item-trigger-scope').split(',')
    boxTriggerScopes.forEach((scope) => {
      const triggers = this.triggered[scope]
      if (triggers) {
        result = result.concat(Object.keys(triggers))
      }
    })
    return result.sort()
  }

  showLoadingAnimation ($box) {
    $box.find('.js-reference-material-loader').removeClass('hidden')
  }

  hideLoadingAnimation ($box) {
    $box.find('.js-reference-material-loader').addClass('hidden')
  }

  updateItemsBox ($box, newLength) {
    const labels = $box.data('itemLabelScope').split(',')
    const triggers = this.getTriggersForBox($box)
    const initialLength = $box.data('initialLength')
    const currentLength = $box.find('.list-group').first().data('currentLength')
    const existingItemPositions = this.existingItemPositions($box)

    if (newLength === undefined || newLength > currentLength) {
      this.showLoadingAnimation($box)
    }

    const payload = {
      labels: labels,
      triggers: triggers,
      initial_length: initialLength,
      current_length: newLength ?? currentLength
    }

    // This is saved so we don't repeatedly load the same triggers
    $box.data('previous-triggers', triggers)

    const config = {
      url: this.endpoint,
      data: payload,
      success: response => {
        $box.parents('.modal').find('.js-loading-reference-material').remove()
        $box.html(response)
        this.indicateUpdates($box, existingItemPositions)
      },
      error: (request, textStatus, errorThrown) => {
        $box.data('previous-triggers', { failed: true }) // Could be set to anything
        $box.html(`<p>Could not load reference material.</p><p>The error was: ${textStatus} ${errorThrown}</p>`)
      },
      global: false
    }

    if (this.disableDefaultLoadingSpinner) {
      config.beforeSend = null
    }

    this.load(config)
  }

  existingItemPositions ($box) {
    // Cannot use a Map because need to support old IE.
    const result = {}
    $box.find('.reference-items__item').each(function (i, e) {
      result[$(e).data('id')] = $(e).data('position')
    })
    return result
  }

  onPaginationLinkClicked (e) {
    e.preventDefault()
    const $link = $(e.currentTarget)
    const newLength = $link.data('newLength')
    const $box = $link.parents('[data-item-label-scope]')
    this.updateItemsBox($box, newLength)
    return false
  }

  // highlight bg of items that have changed or changed position
  indicateUpdates ($box, existingItemPositions, bgColor) {
    const background = bgColor || '#d9edf7'

    // We do not hightlight if first load and nothing to replace
    if (Object.keys(existingItemPositions).length === 0) {
      return
    }

    const updated = $box.find('.reference-items__item').filter(function (i, e) {
      const thisId = $(e).data('id')
      const previousPosition = existingItemPositions[thisId]
      if (previousPosition === undefined) { return true }
      const thisPosition = $(e).data('position')
      return Number(thisPosition) < Number(previousPosition)
    })

    updated.css({ backgroundColor: background })
    window.setTimeout(() => {
      updated.css({ backgroundColor: '#ecf6fb' })
    }, 2000)
  }

  onItemLinkClicked (e) {
    const $itemLink = $(e.currentTarget)
    const urlType = $itemLink.data('url-type')
    const url = $itemLink.data('url')

    switch (urlType) {
      case 'external_link':
        return true // Default action is fine

      case 'modal': {
        const parentModal = $itemLink.closest('.modal')
        this.openInModal(url, parentModal)
        e.preventDefault()
        e.stopPropagation()
        break
      }
      case 'inline': {
        const $box = $($itemLink.parents('[data-item-label-scope]').first())
        this.openInline(url, $box)
        e.preventDefault()
        e.stopPropagation()
        break
      }
      default:
        console.error(`Unknown URL type: ${urlType}`)
    }
  }

  openInModal (url, parentModal) {
    if (parentModal.length > 0) {
      // In a modal, open inside parent modal
      $.get({
        url: url,
        success: response => {
          // Grab parent modal header and body
          const parentModalDialog = parentModal.find('.modal-dialog')
          const parentModalHeader = parentModal.find('.modal-header')
          const parentModalBody = parentModal.find('.modal-body')

          // Detach content of parent modal header and body
          const parentModalHeaderContent = parentModalHeader.children().detach()
          const parentModalBodyContent = parentModalBody.children().detach()

          // Get the parent modal size
          const parentModalIsLarge = parentModalDialog.hasClass('modal-lg')
          const parentModalIsExtraLarge = parentModalDialog.hasClass('modal-xlg')

          // This will restore the parent modal
          let parentModalRestored = false
          const restoreParentModal = () => {
            if (!parentModalRestored) {
              parentModalHeader.empty().append(parentModalHeaderContent)
              parentModalBody.empty().append(parentModalBodyContent)
              if (!parentModalIsLarge) {
                parentModalDialog.removeClass('modal-lg')
              }
              if (parentModalIsExtraLarge) {
                parentModalDialog.addClass('modal-xlg')
              }
              parentModalRestored = true
            }
          }

          // Grab the content out of the loaded modal and stick it in the parent modal
          const modal = $(response)
          parentModalHeader.append(modal.find('.modal-header').children())
          const backButton = $('<a role="button" class="back" data-trigger-on-modal-hide="1"><i class="fa fa-chevron-left"></i> Back</a>')
          backButton.on('click', restoreParentModal)
          backButton.insertAfter(parentModalHeader.find('button'))
          parentModalBody.append(modal.find('.modal-body').children())

          // Set the size of the modal
          if (!parentModalIsLarge) {
            parentModalDialog.addClass('modal-lg')
          }
          if (parentModalIsExtraLarge) {
            parentModalDialog.removeClass('modal-xlg')
          }

          // When the parent modal is hidden, restore it
          // TODO this is never called due to some conflict between the old and new JS
          /*
                    parentModal.one('hidden.bs.modal', () => {
                        console.log('Parent modal hidden');
                        restoreParentModal();
                    });
                     */
        },
        global: false
      })
    } else {
      // Not in a modal - open a modal
      Olj.Modal.load(url)
    }
  }

  openInline (url, $box) {
    this.showLoadingAnimation($box)

    const listGroup = $box.find('.list-group')
    const itemList = listGroup.find('.reference-items__item').detach()
    const footer = listGroup.next('.reference-items__panel-footer').detach()
    const markup = '<div class="list-group-item reference-items__item"></div>' // todo: decouple markup

    $.ajax({
      url,
      beforeSend: null, // prevent the default loader from appearing
      success: response => {
        const itemContainer = $(markup).appendTo(listGroup)
        itemContainer.html(response)
      },
      error: () => {
        const itemContainer = $(markup).appendTo(listGroup)
        itemContainer.html('<div class="alert alert-danger zero-margin-bottom">Failed to load material</div>')
      },
      complete: () => {
        this.hideLoadingAnimation($box)

        const backLink = $('<div class="panel-footer .reference-items__panel-footer text-center"><a class="reference-items__close" href="#"><span class="">Close</span></a></div>').insertAfter(listGroup) // todo: decouple markup
        backLink.on('click', (e) => {
          e.preventDefault()
          listGroup.empty().append(itemList)
          backLink.remove()
          footer.insertAfter(listGroup)
        })
      },
      global: false
    })
  }
}

startIfHTMLRequires('reference_item_items_box', (options) => {
  const itemsBox = new ReferenceItemItemsBox(options)
  Olj.Modern.ReferenceItemItemsBox = itemsBox
  return itemsBox
})

export default ReferenceItemItemsBox
