import { startIfHTMLRequires } from '../init/startIfHTMLRequires.js'

const init = options => {
  $('body').on('click', '[data-flag-modal]', e => {
    const button = $(e.target).closest('[data-flag-modal]')
    const providerId = button.attr('data-flag-modal')
    const pageId = getPageId(button)

    Olj.Modal.load(options.modal_url, {
      page_id: pageId,
      provider_id: providerId
    })
  })
}

const getPageId = button => {
  // There are several attributes that might be used to store the page ID
  return getPageIdFromAttribute(button, 'data-page-id') ||
        getPageIdFromAttribute(button, 'data-obs-id') ||
        getPageIdFromAttribute(button, 'data-reflection-id')
}

const getPageIdFromAttribute = (button, attribute) => {
  return button.closest(`[${attribute}]`).attr(attribute)
}

startIfHTMLRequires('page_flags_modal', init)
