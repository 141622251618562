import { startIfHTMLRequires } from '../init/startIfHTMLRequires'

class ResetChildPasswords {
  constructor (options) {
    Olj.ChildPasswordGeneration.init({
      modalUrl: options.passwordGenerationModalUrl,
      onGeneratePasswords: this.onPasswordsGenerated
    })

    const body = $(document.body)

    body.on('click', '[data-action="generate_password"]', e => this.onGeneratePasswordClicked(e))
  }

  onGeneratePasswordClicked (e) {
    const userId = $(e.target).closest('[data-login-user-id]').attr('data-login-user-id')
    Olj.ChildPasswordGeneration.loadModal([userId], undefined, true)
  }

  onPasswordsGenerated (userIds) {
    // Remove "No password set" label
    $.each(userIds, (k, userId) => $('.child-login-profile-panel[data-login-user-id="' + userId + '"] [data-action="generate_password"]').remove())
  }
}

startIfHTMLRequires('child_login_child_list', (options) => new ResetChildPasswords(options))

export default ResetChildPasswords
