import { startIfHTMLRequires } from 'init/startIfHTMLRequires.js'

class ChildLoginIndex {
  constructor (options) {
    this.options = options

    const body = $(document.body)

    body.on(
      'click',
      '[data-link-path]',
      $.proxy(this.onLinkButtonClicked, this)
    )

    body.on(
      'click',
      '[data-action="expire_invitation"]',
      $.proxy(this.onExpireButtonClicked, this)
    )

    body.on(
      'click',
      '[data-toggle-invitation]',
      e => this.onToggleInviteButtonClicked(e)
    )

    body.on(
      'click',
      '[data-action="show_invitations_by_other_users"],[data-action="show_invitations_by_current_user"]',
      (e) => this.onToggleInvitationsFromOtherUsersClicked(e)
    )
  }

  onLinkButtonClicked (e) {
    e.preventDefault()

    const target = $(e.currentTarget)
    window.location.href = target.closest('[data-link-path]').data('link-path')
  }

  onToggleInviteButtonClicked (e) {
    e.preventDefault()

    const target = $(e.currentTarget)

    this.toggleKey(target.closest('[data-invitation-key]').data('invitation-key'), target.data('toggle-invitation'))
  }

  onExpireButtonClicked (e) {
    e.preventDefault()

    const target = $(e.currentTarget)

    this.expireKey(this.options.expireUrl, target.closest('[data-invitation-key]').data('invitation-key'), target.data('confirmation'))
  }

  toggleKey (key, enabled) {
    $.post(this.options.toggleUrl, { key: key, enabled: enabled }, function (response) {
      if (response.success) {
        Olj.Feedback.show('success', response.message)

        $(`[data-invitation-key="${key}"]`).find('.js-no-children-attached').toggleClass('hidden', enabled)

        $(`[data-invitation-key="${key}"]`)
          .find('[data-display-invitation-enabled]')
          .addClass('hidden')
          .filter('[data-display-invitation-enabled=' + enabled + ']')
          .removeClass('hidden')
      } else {
        Olj.Feedback.show('error', response.message)
      }
    }, 'json')
  }

  expireKey (expireUrl, key, message) {
    let redirectTo = null
    if (typeof this.options.expireRedirectTo !== 'undefined') {
      redirectTo = this.options.expireRedirectTo
    }

    const confirmCallback = function (redirectRoute, key) {
      $.post(redirectRoute, { key: key }, function (response) {
        if (response.success) {
          if (redirectTo !== null) {
            window.location = redirectTo
          } else {
            Olj.Feedback.show('success', response.message)

            $(`[data-invitation-key="${key}"]`).find('[data-action="expire_invitation"]')
              .attr('disabled', true)

            $(`[data-invitation-key="${key}"]`).find('[data-display-invitation-enabled]')
              .addClass('hidden')
            $(`[data-invitation-key="${key}"]`).find('[data-expired="1"]')
              .removeClass('hidden')
          }
        } else {
          Olj.Feedback.show('error', response.message)
        }
      }, 'json')
    }

    bootbox.dialog({
      message: message,
      size: 'large',
      onEscape: true,
      buttons: {
        cancelBtn: {
          label: 'Cancel',
          className: 'btn-default'
        },
        endBtn: {
          label: 'Confirm deletion',
          className: 'btn-primary',
          callback: function () {
            confirmCallback(expireUrl, key)
          }
        }
      }
    })
  }

  onToggleInvitationsFromOtherUsersClicked (e) {
    const onlyFromCurrentUser = $(e.target).closest('[data-action]').attr('data-action') === 'show_invitations_by_current_user'

    $('[data-action="show_invitations_by_other_users"],[data-action="show_invitations_by_current_user"]').prop('disabled', true)

    $.get({
      url: this.options.load_invitations_url,
      data: {
        only_created_by_user: onlyFromCurrentUser ? 1 : 0
      },
      success: function (response) {
        if (response.list_html) {
          const panel = $('#invitations-panel')
          panel.find('.list-group').replaceWith(response.list_html)

          response.count === 0 ? panel.addClass('hidden') : panel.removeClass('hidden')

          $('[data-action="show_invitations_by_other_users"]').toggleClass('hidden', !onlyFromCurrentUser)
          $('[data-action="show_invitations_by_current_user"]').toggleClass('hidden', onlyFromCurrentUser)
        }
      },
      complete: function () {
        Olj.Ajax.afterRequest()
        $('[data-action="show_invitations_by_other_users"],[data-action="show_invitations_by_current_user"]').prop('disabled', false)
      }
    })
  }
}

startIfHTMLRequires('child_login_toggle_login_page', (options) => new ChildLoginIndex(options))
