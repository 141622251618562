import { startIfHTMLRequires } from '../init/startIfHTMLRequires'

const ChildLogin = {
  init: function () {
    const body = $(document.body)

    body.on(
      'click',
      '[data-action="rescind_invitation"]',
      $.proxy(this.confirmRescindAction, this)
    )

    body.on(
      'click',
      '[data-action="copy-url-to-clipboard"]',
      $.proxy(this.onCopyUrlClicked)
    )
  },

  confirmRescindAction: function (event) {
    const el = $(event.target)
    const form = el.closest('form')
    const confirmCallback = function (redirectRoute) {
      $('<input>').attr({
        type: 'hidden',
        name: 'route',
        value: redirectRoute
      }).appendTo(form)

      form.submit()
    }

    event.preventDefault()

    bootbox.dialog({
      message: el.data('confirmation'),
      size: 'large',
      onEscape: true,
      buttons: {
        cancelBtn: {
          label: 'Cancel',
          className: 'btn-default'
        },
        endBtn: {
          label: 'End session',
          className: 'btn-default',
          callback: function () {
            confirmCallback('logged_out_child')
          }
        },
        restartBtn: {
          label: 'Log in again',
          className: 'btn-primary',
          callback: function () {
            confirmCallback('child_authentication')
          }
        }
      }
    })
  },

  onCopyUrlClicked (e) {
    const urlTextBox = $('#' + $(this).data('target'))

    urlTextBox.select()
    document.execCommand('copy')
    Olj.Feedback.show('success', 'Copied the magic link to the clipboard')
  }
}

startIfHTMLRequires('child_login', ChildLogin)

export default ChildLogin
