// This file should only import modern javascript, and only javascript that is used on _most_ pages.
//
// See the [README.md](./README.md) for more detail.
//
// If the javascript applies to just a subset of pages then put it in its own module that is separately compiled
// and only imported on the page(s) concerned. See the files javascript for an example of this.
//
// The *only* thing that should be in this file is import statements for modules that we have written, and
// low file size, important polyfills.
//
// If you feel the need to import some third party module globally - don't! Just require it from the module
// where you are writing your custom javascript. Webpack is clever enough not to import a third party module
// just the once if it is imported many times in different bits of custom code.
//
// If you want to configure a module, then see the example of axios in the preconfigured/axios.js

// Polyfills (below core-js: 120kb)
import 'core-js/features/promise'
import 'core-js/features/array/includes'

import 'child_login/child_login.js'
import 'child_login/child_login_index.js'
import 'child_login/password_policy.js'
import 'child_login/password_reset_individual.js'
import 'user/remove_brute_force_attempts.js'
import dialog from 'dialog/dialog.js'
import 'flags/page_modal'
import { scrollToElement } from './util/scroll'
import './reference_item/items_box'

import { ObsList } from 'main/olj.modern.obs.list.js'
Olj.Modern.ObsList = ObsList

// Setup dialogs
window.dialog = dialog
dialog.init()

Olj.Modern.Utils = {
  scrollToElement: scrollToElement
}
